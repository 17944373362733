import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guard = (next) => {
  if (isUserLoggedIn()) {
    const { role } = getUserData()
    if (role === 'host') {
      return next()
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}
const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (role === 'client' || role === 'host' || role === 'viewer') {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  {
    path: '/pelayanan/jasa-operasional/segment',
    name: 'pelayanan-jasa-operasional-segment',
    component: () => import('@/views/pages/hostonly/SegmentJR.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL', 'Jasa OPS Admin'])
    }
  },
  {
    path: '/host-only/kegiatan-tbbm',
    name: 'host-only-kegiatan-tbbm',
    component: () => import('@/views/pages/hostonly/KegiatanTBBM.vue'),
    // component: () => import('@/views/pages/dashboard/analytics/Analytics.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/host-only/kegiatan-hm',
    name: 'host-only-kegiatan-hm',
    component: () => import('@/views/pages/hostonly/KegiatanHM.vue'),
    // component: () => import('@/views/pages/dashboard/analytics/Analytics.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/host-only/kegiatan-sb',
    name: 'host-only-kegiatan-sb',
    component: () => import('@/views/pages/hostonly/KegiatanSB.vue'),
    // component: () => import('@/views/pages/dashboard/analytics/Analytics.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/host-only/pemilik-tbbm',
    name: 'host-only-pemilik-tbbm',
    component: () => import('@/views/pages/hostonly/PemilikTBBM.vue'),
    // component: () => import('@/views/pages/dashboard/analytics/Analytics.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/host-only/pemilik-hm',
    name: 'host-only-pemilik-hm',
    // component: () => import('@/views/pages/hostonly/pemilikHM.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  },
  {
    path: '/host-only/jetty-position',
    name: 'host-only-jetty-position',
    component: () => import('@/views/pages/hostonly/JettyPosition.vue'),
    // component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      guard: (next) => guardCustom(next, ['all'], ['SAL'])
    }
  }
]
