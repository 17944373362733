import { getUserData, isUserLoggedIn } from '@/auth/utils'

const guardCustom = (next, myAbilitySubject = [], myAbilityAction = []) => {
  if (isUserLoggedIn()) {
    const { role, ability } = getUserData()
    if (
      role === 'client' ||
      role === 'host' ||
      role === 'security' ||
      role === 'viewer'
    ) {
      const check = ability.some(
        (i) =>
          myAbilitySubject.includes(i.subject) ||
          myAbilityAction.includes(i.action)
      )
      if (check) {
        return next()
      }
      return next({ name: 'misc-not-authorized' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next({ name: 'auth-login' })
}

export default [
  /* NAVIGASI TERBITKAN FORM --> JALUR DARAT */
  // {
  //   path: '/monitoring/jalur-darat/form/type-1',
  //   name: 'monitoring-jalur-darat-form-type-1',
  //   // component: () =>
  //   //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     pageTitle: 'Monitoring',
  //     breadcrumb: [
  //       {
  //         text: 'Jalur Darat',
  //         active: true
  //       },
  //       {
  //         text: 'Form Type 1 (T1)',
  //         active: true
  //       },
  //       {
  //         text: 'index',
  //         active: true
  //       }
  //     ],
  //     guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
  //   }
  // },
  // {
  //   path: '/monitoring/jalur-darat/form/type-1/wizard',
  //   name: 'monitoring-jalur-darat-form-type-1-wizard',
  //   component: () =>
  //     import(
  //       '@/views/pages/monitoring/jalur-darat/form-type-1/wizard/index.vue'
  //     ),
  //   meta: {
  //     pageTitle: 'Monitoring',
  //     breadcrumb: [
  //       {
  //         text: 'Jalur Darat',
  //         active: true
  //       },
  //       {
  //         text: 'Form Type 1 (T1)',
  //         to: '/monitoring/jalur-darat/form/type-1'
  //       },
  //       {
  //         text: 'Buat Form',
  //         active: true
  //       }
  //     ],
  //     guard: (next) => guardCustom(next, ['all', 'form1', 'qrcode', 'special'])
  //   }
  // },
  {
    path: '/pelayanan/jasa-operasional/pengajuan/wizard/kegiatan',
    name: 'pelayanan-jasa-operasional-pengajuan-wizard-kegiatan',
    component: () =>
      import(
        '@/views/pages/pelayanan/jasa-operasional/pengajuan/wizard/kegiatan/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'Form Operasional',
          active: true
        },
        {
          text: 'Tambah Kegiatan',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(next, ['all'], ['Jasa OPS Pengguna', 'Jasa OPS Admin'])
    }
  },
  {
    path: '/pelayanan/jasa-operasional/pengajuan-create/wizard',
    name: 'pelayanan-jasa-operasional-pengajuan-create-wizard',
    component: () =>
      import(
        '@/views/pages/pelayanan/jasa-operasional/pengajuan/wizard/CreatePengajuan.vue'
      ),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'Create',
          active: true
        }
      ],
      guard: (next) => guardCustom(next, ['all'], ['Jasa OPS Pengguna'])
    }
  },
  {
    path: '/pelayanan/jasa-operasional/pengajuan/wizard/:id',
    name: 'pelayanan-jasa-operasional-pengajuan-wizard',
    component: () =>
      import(
        '@/views/pages/pelayanan/jasa-operasional/pengajuan/wizard/index.vue'
      ),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'Form Operasional',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  /* index */
  {
    path: '/pelayanan/jasa-operasional/pengajuan',
    name: 'pelayanan-jasa-operasional-pengajuan',
    component: () =>
      import('@/views/pages/pelayanan/jasa-operasional/pengajuan/index.vue'),
    // component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'home',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  {
    path: '/pelayanan/jasa-operasional/pengajuan-in-progress',
    name: 'pelayanan-jasa-operasional-pengajuan-in-progress',
    // component: () =>
    //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'IN-Progress',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  {
    path: '/pelayanan/jasa-operasional/pengajuan-selesai',
    name: 'pelayanan-jasa-operasional-pengajuan-selesai',
    // component: () =>
    //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan',
          active: true
        },
        {
          text: 'Selesai ( Completed )',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  {
    path: '/pelayanan/jasa-operasional/pengajuan-revisi',
    name: 'pelayanan-jasa-operasional-pengajuan-revisi',
    // component: () =>
    //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional',
          active: true
        },
        {
          text: 'Pengajuan ( Daily Timesheet & Document JS )',
          active: true
        },
        {
          text: 'Revisi ( Re-Signature )',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  {
    path: '/pelayanan/jasa-operasional/daily-timesheet',
    name: 'pelayanan-jasa-operasional-daily-timesheet',
    // component: () =>
    //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional ( Digital Signature )',
          active: true
        },
        {
          text: 'Daily Timesheet',
          active: true
        },
        {
          text: 'home',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  },
  {
    path: '/pelayanan/jasa-operasional/document-requisition',
    name: 'pelayanan-jasa-operasional-document-requisition',
    // component: () =>
    //   import('@/views/pages/monitoring/jalur-darat/form-type-1/index.vue'),
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      pageTitle: 'Pelayanan',
      breadcrumb: [
        {
          text: 'Jasa Operasional ( Digital Signature )',
          active: true
        },
        {
          text: 'Document Requisition',
          active: true
        },
        {
          text: 'home',
          active: true
        }
      ],
      guard: (next) =>
        guardCustom(
          next,
          ['all'],
          ['Jasa OPS Pengguna', 'Jasa OPS Admin', 'Jasa OPS Viewer']
        )
    }
  }
]
